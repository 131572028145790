import React, {useEffect} from 'react';
import { Layout, Grid, Menu, Breadcrumb } from 'antd';
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
//import SideNav from 'components/layout-components/SideNav';
//import TopNav from 'components/layout-components/TopNav';
//import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from '../../components/layout-components/HeaderNav';
import PageFooter from '../../components/layout-components/PageFooter';
//import PageHeader from 'components/layout-components/PageHeader';
//import Footer from 'components/layout-components/Footer';
//import AppViews from 'views/app-views';
import navigationConfig from "../../configs/NavigationConfig";
import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    NAV_TYPE_SIDE,
    NAV_TYPE_TOP
} from '../../constants/Theme';
import utils from '../../utils';
import AppViews from "../../views/app-views";
import {setAppLocation} from "../../redux/actions";

const { Header,Content,Footer} = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, setAppLocation }) => {
    const currentRouteInfo = utils.getRouteInfo(navigationConfig,location.pathname)
    const screens = utils.getBreakPoint(useBreakpoint());
    const isMobile = !screens.includes('lg')
    setAppLocation(location.pathname)

    return (
        <Layout >
            <HeaderNav isMobile={isMobile}/>
            <Layout className="app-container">
                <Content>
                    <Switch>
                        <Route path="" component={AppViews} />
                    </Switch>
                </Content>
                <PageFooter/>
            </Layout>
        </Layout>
    )
}

const mapStateToProps = ({theme}) => {
    const { navCollapsed, navType, locale } =  theme;
    return {navCollapsed, navType, locale }
};

//export default connect(mapStateToProps,{setAppLocation})(Views);
export default withRouter(connect(mapStateToProps,{setAppLocation})(AppLayout));