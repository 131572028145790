import antdItIt from 'antd/es/locale/it_IT';
import esMsg from "../locales/it_IT.json";

const ItLang = {
    antd: antdItIt,
    locale: 'it-IT',
    messages: {
        ...esMsg
    },
};
export default ItLang;